import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ProgressBar from "react-bootstrap/ProgressBar";

import image1 from "../assets/images/image1.jpg";

export default function AppAbout() {
  const photo = 60;
  const farming = 30;
  const cities = 10;
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>A propos de Nous</h2>
          <div className="subtitle">
            Services and solutions easy to deploy, flexible, eco-friendly and
            cheaper
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={image1} alt="Cinq développeurs au tour d'un bureau travaillant sur un projet sur des ordinateurs portables pour Dronecate " />
          </Col>
          <Col sm={6}>
            <p>
              
Chez Dronecate, nous proposons des services professionnels utilisant des drones : Photogrammétrie aérienne, Agriculture intelligente & Agriculture de précision, Drone Light Show et Formation en programmation de drones.

Nos solutions sont faciles à déployer, flexibles, respectueuses de l'environnement et économiques.
            </p>
            <p>
              Nous fournissons des services faciles à déployer, flexibles,
              respectueux de l'environnement et moins chers.
            </p>
            <div className="progress-block">
              <h4>Photogrammétrie aérienne</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={photo}
                label={`${photo}%`}
              />
            </div>
            <div className="progress-block">
              <h4>Smart Farming & Precision</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={farming}
                label={`${farming}%`}
              />
            </div>
            <div className="progress-block">
              <h4>Drone Light show & Drone Filming</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={cities}
                label={`${cities}%`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
