import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


/* import { useEffect } from 'react'; //4.1k (gzipped: 1.8k)
import { useState } from 'react'; //4.1k (gzipped: 1.8k)
import AppBlogItem from './blogItem.js';  */


/* function AppBlog () {
  const [articles, setArticles] = useState([]);

  useEffect(() =>{
    let url = `https://newsapi.org/v2/top-headlines?country=be&category=technology&apiKey=${import.meta.env.VITE_API_KEY}`;
    fetch(url).then(response=> response.json()).then(data=> setArticles(data.articles));

  }, [])
  return (
    <div>
      <h2 className='text-center'>Dernières <span className='badge bg-danger'>Actualités</span></h2>
        {articles.map((news,index) =>{
          return <AppBlogItem key={index} title={news.title} description={news.description} src={news.urlToImage} url={news.url} />
        })}
    </div>
  )
} 
 */


 const blogData = [
  {
    id: 1,
    image: require('../assets/images/smartFarming.jpg'),
    time: '15 Oct 2024',
    title: "L'Importance des Drones dans l'Agriculture : Une Révolution grâce au Smart Farming et aux Solutions Écologiques",
    description: "L'agriculture moderne fait face à des défis complexes, et l'utilisation de drones émerge comme une solution innovante, intégrant le concept de Smart Farming et promouvant des pratiques agricoles durables. Voici pourquoi les drones sont devenus des outils incontournables pour les agriculteurs du monde entier : ",
    alt: "Deux drones survolent un champs pour pulvériser",
    link: '#contact'
  },
  {
    id: 2,
    image: require('../assets/images/droneMapping.png'),
    time: '10 Jun 2024',
    title: "L'Importance de l'IA dans le Drone Mapping",
    description: "Le mariage entre l'intelligence artificielle (IA) et les drones a révolutionné la façon dont nous percevons et utilisons le drone mapping. Cette convergence technologique offre des avantages significatifs dans divers domaines, de l'agriculture à la cartographie environnementale. Voici pourquoi l'IA joue un rôle crucial dans le drone mapping :",
    alt: "Une carte indiquant les coordonnées d'un terrain survolé par drone",
    link: '#contact'
  },
  {
    id: 3,
    image: require('../assets/images/dronecateInspection.jpg'),
    time: '08 Nov 2023',
    title: "Survol des Champs: La Révolution de la Surveillance Agricole avec les Drones",
    description: "L'agriculture moderne est en constante évolution, adoptant de nouvelles technologies pour accroître l'efficacité et maximiser les rendements. Une innovation majeure qui a révolutionné la surveillance des champs est l'utilisation de drones. Ces petits aéronefs autonomes, équipés de caméras sophistiquées et d'autres capteurs, offrent aux agriculteurs un outil puissant pour surveiller et gérer leurs cultures de manière plus précise.",
    alt: "Surveillance des plantes par drone",
    link: '#contact'
  }
]

function AppBlog() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Actualités</h2>
          <div className="subtitle">Obtenez les dernières actualités depuis notre blog.</div>
        </div>
        <Row>
          {blogData.map(blog => {
            return (
              <Col sm={4} key={blog.id}>
                <div className='holder'>
                  <Card>
                    {/* Ajout de l'attribut alt */}
                    <Card.Img variant="top" src={blog.image} alt={blog.alt} />
                    <Card.Body>
                      <time>{blog.time}</time>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>
                        {blog.description}
                      </Card.Text>
                      <a href={blog.link} className="btn btn-primary">
                        Lire la suite <i className="fas fa-chevron-right"></i>
                      </a>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppBlog;