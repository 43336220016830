import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from './components/header';
import AppSlide from './components/slide';
import AppAbout from './components/about';
import AppServices from './components/services';
import AppFooter from './components/footer';
import AppBlog from './components/blog';
import AppContact from './components/contact';

function App() {
  return (
    <div className="App">
      <header id="header">
        <AppHeader/>
      </header>
      <main>
        <AppSlide/>
        <about id="about">
        <AppAbout/>
        </about>
        <services id="services">
          <AppServices/>
        </services>
        <blog id="blog">
          <AppBlog/>
        </blog>
       <contact id="contact">
          <AppContact/>
        </contact> 
        <footer id="footer">
          <AppFooter/>
        </footer>
      </main>
    </div>
  );
}

export default App;
