import Carousel from 'react-bootstrap/Carousel';

var slideData = [
  {
    id: 1,
    image: require('../assets/images/img-slide1.jpg'),
    title: 'Photogrammétrie aérienne',
    description: "Explorez le monde à travers les yeux de Dronecate avec une technologie de pointe. Plongez dans l'univers captivant de la photogrammétrie aérienne, de l'inspection innovante et du mapping précis, redéfinissant ainsi la façon dont nous percevons et comprenons notre environnement",
    alt: "Photogrammétrie aérienne pour créer des cartes, des modèles 3D et d'autres données spatiales.",
    link: '#services'
  },
  {
    id: 2,
    image: require('../assets/images/img-slide2.jpg'),
    title: 'Agriculture Intelligente et Précision',
    description: "Transformez votre exploitation avec des solutions écologiques innovantes. Maximisez les rendements tout en minimisant l'impact environnemental. Découvrez le futur durable de l'agriculture aujourd'hui.",
    alt: "Un drone agricole qui irrigue de grandes surfaces et permet d'ajuster précisément la quantité d'eau apportée à vos cultures ",
    link: '#services'
  },
  {
    id: 3,
    image: require('../assets/images/droneLightShow1.PNG'),
    title: 'Service de Drone Light Show et Drone Filming',
    description: "Nous proposons des shows lumineux et des prises de vues aériennes en drone, créant des spectacles personnalisés et des vidéos de haute qualité pour vos événements, lancements, mariages et projets créatifs. Alliant technologie de pointe et impact visuel, nous offrons une alternative innovante aux feux d'artifice et des images spectaculaires pour vos productions.",
    alt: "Spectacle de lumières avec drones",
    link: '#services'
  },
  {
    id: 4,
    image: require('../assets/images/droneProgrammingClass.PNG'),
    title: "Formation en programmation de drones",
    description: "Apprenez à programmer des drones avec notre formation pour débutants et professionnels, couvrant des applications variées comme la cartographie, la surveillance et les spectacles lumineux. Utilisez Python et ROS pour créer des scripts personnalisés, automatiser les vols et optimiser les missions industrielles.",
    alt: "Jeune graçon apprend à programmer un drone avec son ordinateur",
    link: '#services'
  }
]

function AppSlide() {
  return (
    <section id="home" className="slide-block">
       <Carousel>
          {
            slideData.map(slide => {
              return (
                <Carousel.Item key={slide.id}>
                  <img
                    className="d-block w-100"
                    src={slide.image}
                    alt={"slide " + slide.id}
                  />
                  <Carousel.Caption className='caption-shadow'>
                    <h2>{slide.title}</h2>
                    <p className='text-left'>{slide.description}</p>
                    <a className="btn btn-primary" href={slide.link}>Lire la suite <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppSlide;